.about-page-head {
    background-color: $ctcl;
    margin: 0 auto;
}

.head-pic {
    width: 90%;
    margin: 20px auto;
}

@media (max-width: 500px) {
    .head-pic {
        width: 100%;
        margin: 0;
    }
}

.story-block {
    background-color: $cambridge-blue;
    color: $hdcl;
    padding-top:  20px;
    font-size: calc(.5em + 1vw);
    & h2 {
        text-align: center;
        margin-top: 50px;
        font-size: 3rem;
        color: $peach;

    }
    & hr {
        border: 2px solid;
        width: 90%;
        margin-top: 50px;
    }
    & .section { 
        width: 90%;
        margin: 20px auto;
        color: $hdcl;
        & .story-pic {
            max-width: 50%;
            max-height: 50%;
            float: left;
            margin: 0 20px 20px 0;
        }
        & .story-text-block {
            margin: 0 20px;
            & * {
                margin-bottom: 20px;
            }
            & .story-date {
                font-style: italic;
                font-size: 1.5rem;
                color: white;
            }
            & .story-title {
                font-size: 2rem;
                text-align: center;
                color: $peach;
                background-color: $hdcl;
            }
        }
        @media (max-width: 700px) {
            & .story-pic {
                max-width: 100%;
                max-height: 100%;
            }
            & .story-text-block {
                margin: 0;
            }
        }
    }

    & .section-2 {
        & .story-pic {
            float: right;
            margin: 0 0 20px 20px
        }
    }
}