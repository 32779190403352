$hdcl: #201D35;
$hdcl-2: #2C2541;
$bgcl: #A5968D;
$ctcl: #CCB58E;
$peach: #FFE4AD;
$cambridge-blue: #8BA796;


@import url('https://fonts.googleapis.com/css2?family=Jost&family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&display=swap');
@import "./nullifier.scss";
@import "header.scss";
@import "footer.scss";
@import "about.scss";
@import "contact.scss";
@import "contactModal.scss";
@import "home.scss";
@import "adminLogin.scss";
@import "reviewsPage.scss";
@import "gallery.scss";
@import "galleryItemModal.scss";
@import "AdminPanel.scss";
@import "loaders.scss";


* {
    font-family: 'Fragment Mono', monospace;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
    background-color: $bgcl;
    overflow-x: hidden;

}

.page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
}

.action-btn {
    cursor: pointer;
    margin: 30px 20px;
    padding: 1rem;
    font-size: 2.5rem;
    border: 2px solid;
    background-color: $hdcl;
    color: $peach;
    &:hover {
        background-color: $hdcl-2;
        box-shadow: 10px 10px $cambridge-blue;
    }
    &:active {
        transform: translateX(5px);
        transform: translateY(5px);
        box-shadow: 5px 5px $cambridge-blue;
    }
}

.hidden {
    display: none;
} 