.gallery-item-form {
    margin: 20px auto;
    padding: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $ctcl;
}

.sort-settings {
    margin: 30px auto;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    & .fa {
        cursor: pointer;
        font-size: 1.5rem;
        margin: 15px;
        transition: transform 0.2s ease-in-out;
        color: $hdcl-2;
        &:hover {
            transform: scale(1.2)
        }
    }
}

.gallery-items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & .create-area-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        height: 650px;
        width: 350px;
        margin: 1rem;
        border: none;
        background-color: $hdcl-2;
        color: $peach;
        transition: all 0.3s ease-in-out;

        & * {
            margin: 20px;
            cursor: pointer;
        }

        & .plus-sign {
            font-size: 200px;
        }

        & p {
            font-size: 35px;
        }

    }
    
    & .gallery-item {
        position: relative;
        height: 650px;
        width: 350px;
        margin: 1rem;
        border: none;
        background-color: $hdcl-2;
        color: $peach;
        transition: all 0.3s ease-in-out;

        & .gallery-item-img {
            width: 350px;
            height: 350px;
        }

        .gallery-item-info {
            height: 200px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-wrap: break-word;

            & .title {
                text-align: center;
                font-size: 2rem;
            }

            & .description {
                font-size: 1.5rem
            }

            & .price {
                position: absolute;
                right: 10px;
                bottom: 10px;
                text-align: right;
                font-size: 2rem;
                color: white;
            }
        }

        &:hover {
            transition: all 0.3s ease-in-out;
            transform: translate(-2px);
            box-shadow: 8px 8px 5px 0 rgba(0,0,0,0.2);
            
        }
    }

}