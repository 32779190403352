.placeholder-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    & h2, p * {
        font-size: 3rem;
        margin: 50px 10px;
    }

    & .dot:nth-last-child(1) {
        animation: invisibleDots 1s 0.6s ease-in infinite;
      }
    & .dot:nth-last-child(2) {
        animation: invisibleDots 1s 0.4s ease-in infinite;
      }
    & .dot:nth-last-child(3) {
        animation: invisibleDots 1s 0.2s ease-in infinite;
      }
}

@keyframes invisibleDots {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
    }
  }