.page-title {
    margin: 30px auto;
}

.create-area-container {
    position: relative;
    width: 80%;
    min-width: 220px;
    max-width: 800px;
    margin: 10px auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: $ctcl;
    border-radius: 15px;

    & .review-form {
        width: 80%;
        margin: auto;

        & .input-label {
            color: $hdcl;
            font-weight: bold;
          }
          
        & .input {
            width: 100%;
            outline: none;
            margin-bottom: 10px;
            opacity: 0.6;
            cursor: text;
            &.invalid {
                border: solid rgb(241, 78, 78) 3px;
                opacity: 1;
            }

            &:focus {
                border: solid $peach 3px;
                opacity: 1;
            }

          }
        
        & .error {
            display: none;
            font-weight: bold;
            color: red;
        }

        & .textarea {
            width: 100%;
            min-height: 4rem;
            resize: none;
            overflow-y: hidden;
        }

        & .action-btn {
            display: inline-block;
            font-size: 1rem;
            width: 50%;
            margin: auto;
            margin-bottom: 15px;

            &:hover {
                background-color: $hdcl-2;
                box-shadow: 5px 5px $cambridge-blue;
            }
            &:active {
                box-shadow: 2px 2px $cambridge-blue;
            }

            @media (max-width: 550px) {
                width: 100%;
            }

        }
    }
}

.reviews-list-container {
    width: 80%;
    min-width: 220px;
    max-width: 800px;
    min-height: 250px;
    margin: 50px auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: $ctcl;
    border-radius: 15px;

    & .review-container {
        background-color: $cambridge-blue;
        padding: 10px;
        border-radius: 15px;
        margin: 0.5rem;
        font-size: 1.2rem;

        & .review-header {
            margin: 10px;
            & * {
                display: inline-block;
            }

            & .star-rating {
                margin-bottom: 10px;
            }

            & h3 {
                margin-left: 20px;
            }
        }

        & .comment {
            text-align: center;
            margin: 40px 0;
        }

        & .date {
            text-align: right;
            font-style: italic;
            opacity: 0.6;
        }

    }

    & .link-buttons-wrapper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        & * {
            color: rgb(63, 63, 202);
            margin: 10px;
            font-size: 1.2rem;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

        }

    }

}

.star-rating {
    font-size: 38px;
    line-height: 1;
    display: block;
  }
  
  .star {
    display: inline-block;
    color: #ccc;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  
  .star.filled {
    color: $peach;
  }