.modal-container {
    max-width: 600px;
    min-width: 220px;
    min-height: 80%;
    max-height: 100vh;
    overflow-y: auto;
    width: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 18px 0px $peach;
    border-radius: 8px;
    background-image: url(../../images/modalBg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    & .title {
        margin: 20px auto;
    }

    & .contact-form {
        width: 80%;
        margin: auto;

        & .input-label {
            color: $peach;
          }
          
        & .input {
            width: 100%;
            outline: none;
            margin-bottom: 10px;
            opacity: 0.6;
            cursor: text;
            &.invalid {
                border: solid rgb(241, 78, 78) 3px;
                opacity: 1;
            }

            &:focus {
                border: solid $cambridge-blue 3px;
                opacity: 1;
            }

          }

        & .message {
            width: 100%;
            resize: none;
        }

        & .action-btn {
            display: inline-block;
            width: 40%;
            margin: 30px 5%;

            &.reset {
                background-color: rgb(45, 161, 243);
            }

            @media (max-width: 800px) {
                font-size: 1.5rem;
            }

            @media (max-width: 540px) {
                font-size: 1.2rem;
            }

            @media (max-width: 460px) {
                font-size: 1rem;
            }

            @media (max-width: 410px) {
                font-size: 0.8rem;
            }

        }

        & .error {
            direction: rtl;
            font-weight: bold;
            color: red;
        }

    }

    @media (max-width: 500px) {
        & * {
            font-size: 0.9rem;
        }
    }

}
  

