.header {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    background-color: $hdcl; 
    width: 100%;
    min-height: 70px;
        & .brand-title * {
            margin-left: 1rem;
            font-size: 1.5rem;
            display: inline-block;
        }

        @media (min-width: 380px) {
            .brand-title * {
                font-size: 2rem;
            }
        }

        & i {
            color: $peach
        }
        & .navbar, .dropdown {
            display: block;
            position: relative;
            height: 48px;
        }
        & .dropdown-trigger {
            cursor: pointer;
            height: 100%;
            transition: transform 0.2s ease-in-out 0.2s;
            & * {
                cursor: pointer;
            }
            & span {
                display: block;
                width: 45px;
                height: 4px;
                margin-bottom: 10px;
                margin-left: 5px;
                margin-right: 5px;
                position: relative;
                background: white;
                border-radius: 3px;
                z-index: 1;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            & .line-1 {
                transition: transform 0.2s ease-in-out;
            }
            & .line-2 {
                transition: transform 0.2s ease-in-out;
            }
            & .line-3 {
                transition: transform 0.2s ease-in-out;
            }
            &.active {
                transform: rotate(45deg);
                & .line-1 {
                    transform: translateY(14px);
                }
                & .line-2 {
                    transform: rotate(90deg);
                }
                & .line-3 {
                    transform: translateY(-14px);
                }
            }
        }
        
        & .dropdown-menu {
            position: absolute;
            top: 65px;
            right: -290px;
            margin: 0;
            padding: 1rem;
            text-align: center;
            font-size: 1.5rem;
            background-color: $hdcl-2;
            box-shadow: 8px 8px 16px 8px rgba(0,0,0,0.2);
            border-radius: 5px;
            z-index: 1;
            transition: transform 0.3s ease-in-out;
            &.show {
                transform: translateX(-305px);
            }
            & .menu-item {
                margin: 1.5rem;
                position: relative;
                cursor: pointer;
                transition: all 1s ease-in-out;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    transform-origin: bottom right;
                    background-color: white;
                    transition: transform 0.35s ease-in-out;
                  }
                &:hover::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                  }
            }
        }
}