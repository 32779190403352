.admin-login-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    & .input-label {
        color: $peach;
      }
      
    & .input {
        width: 90%;
        outline: none;
        margin: auto;
        margin-bottom: 30px;
        opacity: 0.6;
        cursor: text;

        &:focus {
            border: solid $cambridge-blue 3px;
            opacity: 1;
        }

      }

    & .action-btn {
        display: inline-block;
        width: 60%;
        font-size: 1.4rem;
        margin: 30px auto;
    }

}