.gallery-modal-container {
    max-width: 600px;
    min-width: 220px;
    min-height: 80%;
    max-height: 90vh;
    overflow-y: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 18px 0px $peach;
    border-radius: 8px;
    background-color: $hdcl-2;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    @media (max-width: 780px) {
        min-height: 0;
    }

    & .title {
        margin: 20px auto;
    }

    & .gallery-item-form {
        width: 80%;
        margin: 30px auto;
          
        & .input {
            width: 100%;
            outline: none;
            margin-bottom: 10px;
            opacity: 0.6;
            cursor: text;
            &.invalid {
                border: solid rgb(241, 78, 78) 3px;
                opacity: 1;
            }

            &:focus {
                border: solid $cambridge-blue 3px;
                opacity: 1;
            }

          }

        & .file-input-label {
            position: relative;
            border: 3px dashed $cambridge-blue;
            background-color: lightgray;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 12px;
            cursor: pointer;
            min-height: 100px;

            &.invalid {
                border-color:rgb(241, 78, 78);
            }

            & .drag-tip {
                position: absolute;
                z-index: 0;
                display: block;
                color: black;
            }

            & .file-input {
                z-index: 2;
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            
            & .preview-image {
                z-index: 1;
                margin: auto;
            }
        }

        & .message {
            width: 100%;
            resize: none;
        }

        & .action-btn {

            &.reset {
                background-color: rgb(45, 161, 243);
            }

            &.delete {
                background-color: rgb(241, 78, 78);
            }

            @media (max-width: 800px) {
                font-size: 1.5rem;
            }

            @media (max-width: 540px) {
                font-size: 1.2rem;
            }

            @media (max-width: 460px) {
                font-size: 1rem;
            }

            @media (max-width: 410px) {
                font-size: 0.8rem;
            }

        }

        & .error {
            direction: rtl;
            font-weight: bold;
            color: red;
        }

    }

    @media (max-width: 500px) {
        & * {
            font-size: 0.9rem;
        }
    }

}